<template>
  <div class="data-categories-management" ref="dataCategoriesManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="updateDataType()"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
      >新增</el-button>
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="right"
        label-width="130px"
      >
        <el-form-item label="数据类别标识名：">
          <el-input v-model="form.name" placeholder="请输入平台标识"></el-input>
        </el-form-item>
        <el-form-item label="数据名称：">
          <el-input v-model="form.dataName" placeholder="请输入平台名称"></el-input>
        </el-form-item>
        <el-form-item label="数据业务名称：">
          <el-input v-model="form.businessName" placeholder="请输入平台名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column prop="name" label="数据类别标识名" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="dataSourceName" label="数据来源" width="160"></el-table-column>
      <el-table-column prop="json" label="数据json格式描述" width="400" show-overflow-tooltip></el-table-column>
      <el-table-column prop="dataLabel" label="数据标签" width="160" show-overflow-tooltip></el-table-column>
      <el-table-column prop="dataName" label="数据名称" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="businessName" label="数据业务名"></el-table-column>
      <el-table-column prop="sendType" label="数据发送方式" width="120">
        <template slot-scope="scope">
          {{
          scope.row.sendType === 0 ? '静态全量':scope.row.sendType === 1?'静态增量':'实时'
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <el-button
            @click="updateDataType(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 新增、编辑 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="30%"
    >
      <div class="dialog-body">
        <el-form
          ref="addForms"
          class="dialog-form-edit"
          :model="addForm"
          :rules="rules"
          label-position="left"
          label-width="140px"
        >
          <el-form-item label="数据类别标识名：" prop="name">
            <el-input v-model="addForm.name" :disabled="isEdit?true:false"></el-input>
          </el-form-item>
          <el-form-item label="数据业务名：" prop="businessName">
            <el-input v-model="addForm.businessName"></el-input>
          </el-form-item>
          <el-form-item label="数据标签：" prop="dataLabel">
            <el-input v-model="addForm.dataLabel"></el-input>
          </el-form-item>
          <el-form-item label="数据名称：" prop="dataName">
            <el-input v-model="addForm.dataName"></el-input>
          </el-form-item>
          <el-form-item label="数据来源：" prop="dataSourceName">
            <el-input v-model="addForm.dataSourceName"></el-input>
          </el-form-item>
          <el-form-item label="数据json格式描述：" prop="json">
            <el-input type="textarea" autosize v-model="addForm.json" :disabled="isEdit?true:false"></el-input>
          </el-form-item>
          <el-form-item label="数据发送方式：" prop="sendType">
            <el-select clearable v-model.trim="addForm.sendType">
              <el-option
                v-for="item in typeList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="onDialogClose">取消</el-button>
        <el-button type="primary" size="small" :loading="editLoading" @click="onSure">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryDataTypeList,
  saveDataType
} from '@/api/lib/api.js'
export default {
  data () {
    return {
      tableData: [],
      tableHeight: 0,
      form: {
        name: '',
        dataName: '',
        businessName: '',
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      dialogVisible: false,
      title: '新增',
      rules: {
        name: [
          { required: true, message: "请输入数据类别标识名", trigger: "blur" }
        ],
        businessName: [
          { required: true, message: "请输入数据业务名", trigger: "blur" }
        ],
        dataLabel: [
          { required: true, message: "请输入数据标签", trigger: "blur" }
        ],
        dataName: [
          { required: true, message: "请输入数据名称", trigger: "blur" }
        ],
        dataSourceName: [
          { required: true, message: "请输入数据来源", trigger: "blur" }
        ],
        json: [
          { required: true, message: "请输入数据json格式描述", trigger: "blur" }
        ],
        sendType: [
          { required: true, message: "请选择数据发送方式", trigger: "blur" }
        ],
      },
      addForm: {
        businessName: "",
        dataLabel: "",
        dataName: "",
        dataSourceName: "",
        json: "",
        name: "",
        sendType: '0'
      },
      editLoading: false,
      //0 全量 1 增量 2 实时
      typeList: [
        {
          dictCode: '0',
          dictValue: '静态全量'
        },
        {
          dictCode: '1',
          dictValue: '静态增量'
        },
        {
          dictCode: '2',
          dictValue: '实时'
        }
      ],
      isEdit: null,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      this.onSearch();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.dataCategoriesManagement.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16;
    },
    updateDataType (row) {
      this.dialogVisible = true;
      this.title = row?.id ? '编辑' : '新增';
      this.isEdit = row?.id;
      if (row?.id) {
        this.addForm = { ...row };
        this.addForm.sendType = row.sendType.toString();
      }
    },
    onDialogClose () {
      this.dialogVisible = false;
      this.isEdit = null;
      this.addForm = {
        businessName: "",
        dataLabel: "",
        dataName: "",
        dataSourceName: "",
        json: "",
        name: "",
        sendType: '0'
      }
    },
    onSearch () {
      this.getListFun();
    },
    getListFun () {
      queryDataTypeList(this.form).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data?.list;
          this.total = res.data.total;
        }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page;
      this.getListFun();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size;
      this.onSearch();
    },
    onSure () {
      let data = {};
      if (this.isEdit) {
        data = { ...this.addForm, id: this.isEdit }
      } else {
        data = { ...this.addForm }
      }
      this.$refs.addForms.validate(valid => {
        if (valid) {
          this.editLoading = true;
          saveDataType(data).then(res => {
            if (res.code === 1000) {
              this.$message.success(res.msg)
              this.onDialogClose();
              this.onSearch();
            } else {
              this.$message.error(res.msg);
            }
            this.editLoading = false;
          })
        }
      })

    }
  }
}
</script>
<style lang="scss" scoped>
.data-categories-management {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
.dialog-form-edit {
  padding: 0 20px;
  .el-select {
    width: 100% !important;
  }
}
.dialog-body {
  max-height: 60vh;
  overflow-y: auto;
}
</style>